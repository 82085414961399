import React from 'react';
import styled from 'styled-components';

const StyledTag = styled.li`
  color: #000;
  font-weight: 500;
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  margin-right: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 13px 17px -5px rgb(50 50 93 / 15%),
    0 8px 8px -8px rgb(0 0 0 / 30%);
`;

function Tag({ children }) {
  return <StyledTag>{children}</StyledTag>;
}

export default Tag;
