import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Tag from '../components/Tag';
import { StyledContainer } from '../layouts/styles';
import Metadata from '../components/Metadata';

const StyledHeadingImage = styled.div`
  h2 {
    margin-bottom: 2rem;
  }

  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  gap: 3rem;

  p {
    font-size: 1.5rem;
  }
`;

const StyledColumnContainer = styled.div`
  @media (min-width: 920px) {
    display: flex;
    gap: 3rem;

    section {
      width: 50%;
    }
  }
`;

const StyledContentList = styled.ul`
  padding: 0;
  margin: 0 auto;
`;

const StyledContentListWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const StyledHeadingContainer = styled.div`
  max-width: 80%;
`;

function Jugendliche() {
  return (
    <>
      <Metadata title='Jugendliche' />
      <StyledContainer>
        <StyledHeadingContainer>
          <h1>Jugendliche (12-18 Jahre)</h1>
        </StyledHeadingContainer>
        <Section>
          <StyledHeadingImage>
            <div>
              <h2>Kennenlernen</h2>
              <p>
                Bei Interesse vereinbaren Sie gerne ein unverbindliches
                Kennenlerngespräch mit uns.
              </p>
            </div>
            <StaticImage
              src='../images/Jugendliche_Seite.png'
              alt='Bild eines Jugendlichen'
            ></StaticImage>
          </StyledHeadingImage>
        </Section>
        <Section heading='Anmeldegespräch' backgroundColor='primary'>
          <StyledContentListWrapper>
            <StyledContentList>
              <Tag>Kennenlernen</Tag>
              <Tag>Anamnese</Tag>
              <Tag>Bedarfsermittlung</Tag>
            </StyledContentList>
          </StyledContentListWrapper>
        </Section>
        <Section>
          <h2>Therapiebeginn</h2>
          <p>
            Sobald die Kostenübernahme durch die zuständige Behörde genehmigt
            wurde, beginnen wir mit der Therapie.
          </p>
          <ul>
            <li>
              Kennenlernen des Jugendlichen
              <ul>
                <li>Womit beschäftigt sich das er/sie gerne?</li>
                <li>Was ist ihr/ihm wichtig?</li>
                <li>Welche Stärken und Ziele hat er/sie?</li>
              </ul>
            </li>
            <li>
              Test- und Entwicklungsdiagnostik
              <ul>
                <li>
                  Welche Fähigkeiten kann er/sie in seinem Alltag einsetzen?
                </li>
                <li>Wie hoch ist der Unterstützungsbedarf?</li>
                <li>
                  Welche Fähigkeiten sind für sie/ihn und sein Umfeld von
                  Bedeutung und befähigen ihn/sie zu mehr Eigenständigkeit,
                  Selbstbestimmung und Teilhabe?
                </li>
              </ul>
            </li>
            <li>
              Kommunikationsfähigkeiten einstufen
              <ul>
                <li>Kann er/sie effektiv mit seiner Umwelt kommunizieren?</li>
                <li>
                  Ist die Einleitung einer alternativen Kommunikationsform
                  notwendig?
                </li>
              </ul>
            </li>
            <li>
              Herausforderungen verstehen
              <ul>
                <li>
                  Welche Verhaltensweisen sind gefährdend für sie/ihn oder
                  andere?
                </li>
                <li>
                  Gibt es Verhaltensweisen, die ihn/sie daran hindern, zu lernen
                  und eigene Ziele zu erreichen (sog. Hürden des Lernens)?
                </li>
                <li>Welchem Zweck dienen diese Verhaltensweisen?</li>
                <li>
                  Welche Strategien und Kompetenzen fehlen ihr/ihm, um sicher
                  und erfolgreich zu handeln?
                </li>
              </ul>
            </li>
            <li>Regelmäßige Entwicklungs- und Feedbackgespräche</li>
          </ul>
        </Section>

        <Section>
          <StyledColumnContainer>
            <section>
              <h2>Autismusspezifische Einzeltherapie</h2>
              <p>
                Auf Grundlage der Anamnese und der Test- und
                Entwicklungsdiagnostik wird ein individuelles Therapieprogramm
                entwickelt. Dieses wird von einer Fachkraft in einer
                1:1-Situation in unseren Räumlichkeiten umgesetzt.
              </p>
            </section>
            <section>
              <h2>Beratung und Anleitung</h2>
              <p>
                Die Eltern und das soziale Umfeld des Kindes werden regelmäßig
                beraten und angeleitet. Diese Termine finden in den Räumen des
                Therapiezentrums, im häuslichen oder schulischen Umfeld statt.
              </p>
            </section>
          </StyledColumnContainer>
        </Section>
        <Section>
          <StyledHeadingImage>
            <div>
              <h2>Elternzusammenarbeit</h2>
              <p style={{ fontSize: '1.25rem' }}>
                Wenn Ihr Kind unsere Autismustherapie erhält, stehen wir Ihnen
                jederzeit für einen Austausch zur Verfügung. Die Zusammenarbeit
                mit Ihnen liegt uns aus verschiedenen Gründen sehr am Herzen:
              </p>
            </div>
            <StaticImage
              src='../images/Elternzusammenarbeit_Jugendliche.jpg'
              alt='Eltern eines Kindes'
            ></StaticImage>
          </StyledHeadingImage>
        </Section>
        <Section>
          <p>
            Wir glauben, dass Sie Ihr Kind am besten kennen und wir respektieren
            Sie als wichtigste Bezugspersonen Ihres Kindes. Sie können uns daher
            wertvolle Informationen über Ihr Kind geben und einschätzen, ob wir
            „auf dem richtigen Weg” sind.
          </p>
          <p>
            Darüber hinaus profitiert Ihr Kind von einer ganzheitlichen
            Förderung. Das Leben findet nicht nur in der Therapie statt. Ziele,
            Fähigkeiten und Vorgehensweisen sollten der Lebenswirklichkeit Ihres
            Kindes nah kommen. Wenn alle wichtigen Personen im Umfeld des Kindes
            gemeinsam an einem Strang ziehen, schafft das Klarheit und
            schnellere Erfolgserlebnisse für alle.
          </p>
          <p>
            Wir sind davon überzeugt, dass Ihr Kind sein Bestes gibt und das
            sollten wir durch eine gute Zusammenarbeit ebenfalls tun.
          </p>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Jugendliche;
